<script >

export default {
  data(){
    return{
      showTooltip:false,
      dialogVisible1: false,
      qrCodePath: '',       // 存储二维码路径
    }
  },
  computed:{

  },
  mounted() {
  },


  methods:{
    showModalTrue(imagePath) {
      this.dialogVisible1 = true;    // 打开模态框
      // console.log('二维码路径:', imagePath); // 打印路径，检查是否正确
      // this.qrCodePath = '../assets/new/二维码.jpg';  // 设置二维码图片路径
      this.qrCodePath = imagePath;  // 设置二维码图片路径
    },
    toggleTooltip() {
      this.showTooltip = !this.showTooltip; // 切换二维码显示与隐藏
    },
    uptop(){
      console.log("zhiding")
      this.$emit("uptop")
    },
    upkefu(){
      window.open("https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=3588905289569767&webPageTitle=在线咨询")
    },
    showNet(){
      window.open("/netFeedback")
    },
    // showProduct(){
    //   console.log("this.isPC",this.isPC)
    //   if(this.isPC){
    //     this.$emit("showProduct", true)
    //   }else{
    //     this.$emit("showProduct", false)
    //   }
      
    // },
    showProduct(){
        this.$emit("showProduct", true)
    },
  }
}
</script>

<template>
  <div>
    <el-dialog :visible.sync="dialogVisible1"
               :close-on-click-modal="true" >
      <div class="qr-code-wrapper">
        <img :src="qrCodePath" alt="二维码" class="qr-code-image">
      </div>
    </el-dialog>
    <div class="pc-style">

      <div class="uptop"  @click="uptop"></div>
      <div style="padding:0 0 0 0 ;" @click="upkefu">
        <div class="upkefu" ></div>
        <!--      <div class="title1" > 在线咨询</div>-->
      </div>
      <div style="padding:0.5vh 0 0 0" @click="showProduct">
        <div class="upkefu1"></div>
        <!--      <div class="title1" > 服务反馈</div>-->
      </div>
      <div style="padding: 1vh 0 2vh 0">
        <!-- <div class="upkefu3"></div> -->
        <!-- <div class="title1" style="font-weight: 400;"  > 关注我们</div> -->
        <div class="wechaticon" >

          <!--      <div class="wechaticon"> </div>-->
          <img src="../assets/new/二维码.jpg" class="wechat-icon" @click="showModalTrue(require('@/assets/new/二维码.jpg'))"/>
          <div class="title1" style="font-weight: 400;color: black"  > 关注<br>我们</div>
        </div>
        <!--    <div class="tooltip" v-if="showTooltip">-->

        <!--      <img src="../assets/new/二维码.jpg"/>-->
        <!--    </div>-->
      </div>
    </div>
  </div>

</template>

<style scoped lang="scss">
.qr-code-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;z-index: 99;
}

.qr-code-image {
  width: 400px !important;   /* 强制设定宽度 */
  height: 400px !important;
  object-fit: cover; /* 确保图片填满整个弹窗并可能裁剪 */
}
.pc-style {
  z-index: 99;
  cursor: pointer;
  position: fixed;
  top: 40%;
  right: 2vw;
  padding:0.5vh 0.1vw;
  width: fit-content;
  height: fit-content;
  border-radius: 53px;
  //border:1px solid #dadada;
  // background-color: rgb(50, 119, 220);
  // background-color: #15257b;
  background-color: white;

  //border-radius: 50%;
  box-shadow: 5px 0 14px 0px #dadada;

  .uptop {
    width: 2vw;
    height: 2vw;

    margin: 1vh auto;
    background-image: url("../assets/home/up.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }

  .upkefu {
    margin: 0.5vh auto;
    width: 2vw;
    height: 2vw;
    background-image: url("../assets/home/kefu.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .upkefu1 {
    margin: 0.5vh auto;
    width: 2vw;
    height: 2vw;
    background-image: url("../assets/home/mess.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .wechaticon{
    margin: 0.5vh auto;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .sidebar {
  padding: 0 0 1vh;
  position: relative;
}
  .upkefu3 {
    margin: 0.5vh auto;
    width: 2vw;
    height: 2vw;
    background-image: url("../assets/关注1.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .title1{

    font-size: 0.5vw;text-align: center;;color: white;  cursor: pointer; /* 可点击手势 */
  }
  .wechaticon {
  //height:3vh;
  width: 2.5vw;
  // display: flex;
  justify-content: center;
  text-align: center; 
  margin:0 auto;
  z-index: 99999
    // background-color: #f0f0f0;
    // transition: background-color 0.3s; /* 平滑过渡效果 */
  }
  .wechat-icon {
    object-fit: contain;
    width: 2vw;   /* 设置图片宽度 */
    height: 2vw;  /* 设置图片高度 */
}
 .tooltip {
    /* 当showTooltip为true时显示提示框 */
    opacity: 1;
    width: 15vh;
    height: fit-content;
    position: fixed;
    right: 6vw;
    top: 58vh;
   padding: 1vh 0.5vw;
   background-color: white;
  }

  // .tooltip {
  //   opacity: 0;
  //   visibility: hidden;
  //   transition: opacity 0.3s ease, visibility 0s 0.3s;
  // }

  // .wechaticon:hover + .tooltip {
  //   opacity: 1;
  //   visibility: visible;
  //   transition: opacity 0.3s ease, visibility 0s 0s;
  // }
  .qr-codes {
  position: absolute;
  top:3vh; /* 从文字底部开始 */
  left: 50%;
  transform: translateX(-50%);
  display: none; /* 默认隐藏 */
  flex-direction: column;
  align-items: center;
  gap: 1vh;
}

.qr-code {
  width: 3vw;
  height: 3vw;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  opacity: 0; /* 隐藏二维码 */
  transform: translateY(10px);
  transition: opacity 0.3s, transform 0.3s; /* 平滑过渡效果 */
}

.sidebar:hover .qr-codes {
  display: flex;
}

.sidebar:hover .qr-code {
  opacity: 1;
  transform: translateY(0); /* 上下动画效果 */
}
  .feedback {
    padding: 0.5vh 0;
    margin: 1vh auto;
    font-size: 0.8vw;
    text-align: center;
    color: white;
  }

}
.mobile-style{
  cursor: pointer;
  position: fixed;
  top:40%;
  z-index: 98  ;
  right:0;
  background-color: rgb(8, 78, 137);
  padding:1vw;
  width: 15vw;
  height:fit-content;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  //border-radius: 50%;
  //border: 5px solid #1295d9;
  .uptop{
    width:25px;
    height:25px;
    margin:5px auto;
    background-image: url("../assets/up.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .upkefu{
    margin:0.5vh auto;
    width:7vw;
    height:7vw;
    background-image: url("../assets/Vector (3).png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .upkefu1{
    margin:0.5vh auto;
    width:9vw;
    height:9vw;
    background-image: url("../assets/Frame.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .upkefu3 {
    margin: 0.5vh auto;
    width: 9vw;
    height: 9vw;
    background-image: url("../assets/关注1.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .title1{

    font-size: 3vw;text-align: center;margin-top: 0.5vh;color: white;
  }
  .wechaticon {
    // height:9vh;
  width:14vw;
  // display: flex;
  justify-content: center;
  text-align: center; 
  margin:0 auto;
  z-index: 99999
  }
  .wechat-icon {
    object-fit: contain;
    width: 35px;   /* 设置图片宽度 */
    height: 35px;  /* 设置图片高度 */
}
 .tooltip {
    /* 当showTooltip为true时显示提示框 */
    // opacity: 1;
    opacity: 1;
    width: 8vh;
    height: 8vh;
    position: fixed;
    right: 17vw;
    top: 55vh;
  }

  // .wechaticon:hover {
  //   background-color: #3498db; /* 鼠标悬停时的背景颜色 */
  // }

  .feedback{
    cursor: pointer;
    width: fit-content;
    height:fit-content;
    color: white;
    font-size: 8px;
    margin:0 auto;
    text-align: center;
    //padding: 10px 0;
    display: flex;
    justify-content: center;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    //border: 5px solid #1295d9;
  }
}
</style>
