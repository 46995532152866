<!--
 * @Author: twy+1-at-831623936441 ISfIgyagCK159zOXbALBCYfPJsXX7JI+UcjxsoGvWhTuiwzjxNFOeYUELbg=
 * @Date: 2025-01-17 17:31:10
 * @LastEditors: twy+1-at-831623936441 ISfIgyagCK159zOXbALBCYfPJsXX7JI+UcjxsoGvWhTuiwzjxNFOeYUELbg=
 * @LastEditTime: 2025-02-20 10:08:50
 * @FilePath: \ground\src\views\component\Map.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<script>

import {defineComponent} from "vue";
import Personcard from "@/components/personcard.vue";

export default defineComponent({
  components: {Personcard},
  methods:{
    button1(){
      this.$emit("about")
    },
    button2(){
      this.$emit("login1")
    }
  }
})
</script>

<template>
<div class="MapContainer">

  <div class="title">
    全球油藏地质专家共享服务平台
  </div>
  <div class="title2">
    <span>
      全行业专业人才社区，随时随地帮助您解决油气开发的一切难题。<br>
    </span>
    <!-- 汇聚全球人才，在线输出油气田开发解决方案，助力油气田增产提效 -->
  </div>

  <personcard style="margin-top: 3vh"></personcard>
  <div class="mapback">
    <img src="../../assets/mapback.png">
  </div>
  <div class="mapbutton">
    <div class="button1" @click="button1">
      了解更多
    </div>
    <div class="button1" @click="button2">
      登录
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.MapContainer{
  .title{
    //color: white;
    padding:15px 0 15px 0;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-content: center;
    letter-spacing: 0.5vw;
  }
  .title2{
    font-size: 16px;
    padding: 0 10px 0 0;
    //color: white;
    span{
      font-size: 16px;
      letter-spacing: 0.5vw;
      //font-weight: bolder;
    }
  }
  .mapback{
    width: 100%;
    margin-top: 1vh;
  }
  .mapbutton{
    font-size: 14px;
    //color: white;
    gap: 2vw;
    display: flex;
    padding: 3vh;
    .button1{
      margin: auto;
      padding: 0.3vh;
      border-radius: 14px;
      text-align: center;
      width: 30vw;
      border: black 1px solid;
    }
  }
}
</style>
