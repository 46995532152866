<template>
  <div>
    <div v-if="model === false" >
      <div class="layer1">
        <div class="leftLayer" @click="back"></div>
<!--        <div></div>-->
        <div class="rightLayer" >

          <el-popover
              placement="bottom"
              popper-class="down-popover"
              trigger="click"
              width="900"
          >
            <div style="width: 100%;position: relative;">
              <div class="topLayer">
                <el-input v-model="keyTitle" style="border: none" placeholder="请输入您要查找的GPT或内容关键词" @input="getSearch"></el-input>
<el-button type="primary" style="margin-left: 2vw">搜索</el-button>
              </div>
              <div class="searchBack" v-if="search.length>0">
                <li v-for="item in search" :key="item">
                  <div class="searchContent" @click="routerSkip(item.link)">
                    <div class="title1" v-html="item.title"></div>
                    <div class="title2" v-html="item.contend"></div>
                  </div>

                </li>

              </div>
              <div v-else class="searchBackno">
                暂未检索到您要查找的关于<span style="color: #eb7c31;margin: 0 0.5vw">“{{keyTitle}}”</span>的信息，换个关键词试试吧！
              </div>
            </div>

            <div slot="reference" class="search" style="background-color: transparent">

              <i class="el-icon-search"
                 style="font-size: 1vw;color: white;font-weight: bold;"></i></div>
          </el-popover>
          <div class="communicityGPT" @click="gpts">GPT社区</div>

          <div class="communicity" @click="show">诚邀合作</div>
          <div class="communicitynet" >
            <div class="selectEn" @click="toCN()" style="padding: 0.8vh 0.5vw">中文</div>
            <span>|</span>
            <div class="selectCn" @click="toEN()" style="padding: 0.8vh 0.5vw">English</div>


<!--            <el-select v-model="value"        @change="English" placeholder="请选择">-->
<!--              <el-option-->
<!--                  v-for="item in options"-->
<!--                  :key="item.value"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value">-->
<!--              </el-option>-->
<!--            </el-select>-->
          </div>
          <div id="autoScroll" @click="toggleScroll"></div>
        </div>
        </div>


    </div>
    <div v-else>
      <div class="layerModel">
        <div v-if="showsearch" class="searchnew">
          <div style="float: right;margin: 15px"><i class="el-icon-circle-close" style="font-size: 20px;font-weight:bold;color: #163eee"
                                                    @click="showsearch=false"></i></div>
          <div class="search">
            <el-input v-model="keyWords" style="border: none" placeholder="请输入您要查找的GPT或内容关键词" @input="getSearch"></el-input>

            <i class="el-icon-search"
               style="margin-right: 15px;z-index: 999999999;font-size: 16px;color:gray;font-weight: bold;height: 45px;display: flex;align-items: center;justify-content: center"></i>
          </div>

          <div v-if="search.length>0" class="searchBack">
            <li v-for="item in search" :key="item">
              <div class="searchContent" @click="routerSkip(item.link)">
                <div class="title1" v-html="item.title"></div>
                <div class="title2" v-html="item.contend"></div>
              </div>

            </li>

          </div>
          <div v-else class="searchBackno">
            暂未检索到您要查找的关于<span style="color: #eb7c31;margin: 0 0.5vw">“{{ keyWords }}”</span>的信息<br>换个关键词试试吧！
          </div>
        </div>
        <div class="leftLayer" @click="back"></div>
<!--        <div></div>-->
        <div class="rightLayer">
          <div style="display: flex">
            <div class="login" style="margin-right: -10px" @click="showsearch=true"><i style="color: black" class="el-icon-search"/></div>
            <div class="communicitynet" style="margin-top: -1px;margin-right: -5px;color: black">
              <el-select v-model="value" placeholder="请选择" @change="English">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
            <el-dropdown>
              <div
                  class="el-dropdown-link"
                  style="display: flex; align-items: center"
              >
                <img :src="three" class="communicitynet"
                     style="height: 23px;width: 20px;border-radius:0;display: flex;justify-content: center;align-items: center">
              </div>

              <el-dropdown-menu slot="dropdown" style="text-align: right">

                <el-dropdown-item
                    class="hoverItem"
                    style="display: flex; height:32px;text-align: left;font-size: 12px;font-family: arial, sans-serif, 'Microsoft Yahei'">
                  <div @click="show">
                    <span style="margin-top: 0">诚邀加盟</span>
                  </div>
                </el-dropdown-item>

                <el-dropdown-item
                    style="display: flex; text-align: left;font-size: 12px;height: 32px"
                >
                  <div @click="gpts">GPT社区</div>


                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
    <partnerTip :dialogVisible="dialogconsult" @hideDialog="hide"></partnerTip>
  </div>
</template>


<script>
// import commentFeedback from "@/components/commentFeedback.vue";
// import productFeedback from "@/components/productFeedback.vue";
// import netFeedback from "@/components/netFeedback.vue";
import {
  logOut,
  userDetail,
  // getAuthorize,
  // getLoginStatus,
  // deleteUserAgent,
} from "@/api/user";
// import Fingerprint2 from "fingerprintjs2";
import PartnerTip from "@/components/partnerTip.vue";
import Fingerprint2 from "core-js/internals/queue";

export default {
  name: "headtip",
  props: [],
  components: {PartnerTip},
  data() {
    return {      dialogComment:false,
      dialogProduct:false,
      dialogNet:false,
      model: false,
      keyTitle: "",
      value: '选项2',
      options: [{
        value: '选项1',
        label: 'EN'
      }, {
        value: '选项2',
        label: '中文'
      },],
      search: [
        {
          link: "gptTranslate",
          title: "工艺类GPTs",
          contend: "工艺类GPTs，不仅有助于提高开发效果和采收率，同时降低开发过程中的风险损失，确保油气田的安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "解释类GPTs",
          contend: "解释类GPTs，基于油气开发目标解释，不仅有助于工程作业人员实时掌握油气藏地质地质条件以及工程作业情况，还为开发过程中的决策和调整提供了有力支持安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "工艺类GPTs",
          contend: "工艺类GPTs，不仅有助于提高开发效果和采收率，同时降低开发过程中的风险损失，确保油气田的安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "解释类GPTs",
          contend: "解释类GPTs，基于油气开发目标解释，不仅有助于工程作业人员实时掌握油气藏地质地质条件以及工程作业情况，还为开发过程中的决策和调整提供了有力支持安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "工艺类GPTs",
          contend: "工艺类GPTs，不仅有助于提高开发效果和采收率，同时降低开发过程中的风险损失，确保油气田的安全稳定开发"
        },
        {
          link: "gptTranslate",
          title: "解释类GPTs",
          contend: "解释类GPTs，基于油气开发目标解释，不仅有助于工程作业人员实时掌握油气藏地质地质条件以及工程作业情况，还为开发过程中的决策和调整提供了有力支持安全稳定开发"
        },
      ],
      keyWords: "",
      userName: "",
      avator: require("@/assets/user.jpg"),
      userId: localStorage.getItem("userId"),
      userInfo:"",
      // userId:48,
      showsearch: false,
      dialogconsult: false,
      baseURL: this.$utils.baseURL,
      three: require("@/assets/three.png"),
      scrollState: false,
      lastDistance: 0,
      lastTime:0,
    };
  },
  created() {
    this.getSearch()
    console.log("userId", this.userId);

  },
  watch: {
    "$store.state.userId"(newValue, oilValue) {
      this.userId = newValue;
      if (newValue && newValue != oilValue) {
        this.getUser();
        this.ifComplete()
      }
    },
  },
  mounted() {
    const screenWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
    const screenHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

    // Log the values1731 809   1653 834   1912 932
    console.log("Screen Width: ", screenWidth);
    console.log("Screen Height: ", screenHeight);
    if (screenWidth <= 700) {
      this.model = true;
    } else {
      this.model = false;
    }
    let murmur;
    let that = this;
    var options = {
      excludes: {
        enumerateDevices: true,
        platform: true,
        addBehavior: true,
        cpuClass: true,
        deviceMemory: true,
        hardwareConcurrency: true,
        colorDepth: true,
        screenResolution: true,
        availableScreenResolution: true,
        indexedDb: true,
        webgl: true,
        plugins: true,
        canvas: true,
        pixelRatio: true,
      },
    };
    console.log(options, "options");
    Fingerprint2.get(options, (components) => {
      const values = components.map(function (component, index) {
        if (index === 0) {
          return component.value.replace(/\bNetType\/\w+\b/, "");
        }
        return component.value;
      });
      murmur = Fingerprint2.x64hash128(values.join(""), 31);
      that.murmur = murmur;
      localStorage.setItem("murmur", murmur);
      that.isLogin();
    });
    this.userId = localStorage.getItem("userId");
    if (this.userId) {
      this.getUser();
      this.ifComplete()
    }
  },
  methods: {  
      hideComment() {
      localStorage.setItem("skip", 0)
      this.dialogComment = false;
    },
    hideProduct(){
      localStorage.setItem("skip", 0)
      this.dialogProduct = false;
    },
    hideNet(){
      localStorage.setItem("skip", 0)
      this.dialogNet = false;
    },showComment(){
      this.dialogComment=true
      // console.log("chufa")
    },
    showProduct(){
      this.dialogProduct=true
    },
    showNet(){
      this.dialogNet=true
    },
    getSearch() {
      console.log( this.search.length)
      this.$axios
          .get("api/search/getThirdByName_Introduce?keyword=" + this.keyTitle, {
            // .get("http://192.168.200.50:16542/search/getThirdByName_Introduce?keyword=" + this.keyTitle, {
            headers: {
              Authorization: "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjUyNjZiN2QxLTNhOWUtNDBiOC05MGJlLTNjMTU1OGM3YmI3YiJ9.OsIDjD4UL8AZHu5j5KO-26IQwJu6uVvu59KNM18vuLmjzXMJFl-lX2vyyw_4L1AXC6wot6lpxhv_JZUVe1LokQ",
            },
          })
          .then((res) => {
            console.log("search", res.data)
            this.search = []
            var searchdata = res.data.data.thirdPageList

            searchdata.forEach((item, index) => {
              console.log(res.data.data.keywordList[0]!=="")
              if(res.data.data.keywordList[0]!==""){
                item.leighttitle = this.highlightText(item.title,  res.data.data.keywordList);
                item.leightcontend = this.highlightText(item.introduce,  res.data.data.keywordList);
                this.$set(this.search, index, {
                  link: item.url,
                  title: item.leighttitle,
                  contend: item.leightcontend,
                });
              }
              else{
                this.$set(this.search, index, {
                  link: item.url,
                  title: item.title,
                  contend: item.introduce,
                });
              }


            })
            console.log("成都", this.search.length)
          })
          .catch((err) => {
            console.log(err);
          });
    },
    highlightText(text, keywords) {
      const regex = this.createHighlightRegex(keywords);
      return text.replace(regex, (match) => `<span style="color: #eb7c31">${match}</span>`);
    },
    createHighlightRegex(keywords) {
      const escapedKeywords = keywords.map(keyword => keyword.replace(/[/\\^$*+?.()|[\]{}]/g, '\\$&'));
      const regexPattern = escapedKeywords.join('|');
      return new RegExp(regexPattern, 'gi');
    },
    ifComplete(){
      this.$axios
          .get("/info/gpts_user/judgment_info/" + this.userId)
          .then((res) => {
            if(res.data.msg=="信息已完善"){
              localStorage.setItem("infoComplete",1)
            }
            else{
              localStorage.setItem("infoComplete",0)
            }

            console.log("--------ren",res.data)

          })
          .catch((err) => {
            console.log(err);
          });

    },
    isLogin() {
      let murmur = localStorage.getItem("murmur");
      let idass = this.$store.state.idass;
      let data;
      console.log(idass, "idass");
      if (idass && idass != "undefined") {
        data = {
          userAgent: murmur,
          idass: idass,
        };
      } else {
        data = {
          userAgent: murmur,
        };
      }
      // getLoginStatus(JSON.stringify(data))
      this.$axios
          .post("https://www.oilgasinfoai.com/auth/oauth/getLoginStatus", data)
          .then((res) => {
            console.log(res, "res11111");
            let options = this.$route.query;
            if (res.data.data.isLogin == true && this.$store.state.access_token) {
              this.getUser();
              this.ifComplete()
            } else if (
                res.data.data.isLogin == true &&
                !this.$store.state.access_token &&
                !options.code
            ) {
              this.login();
            } else if (
                res.data.data.isLogin == false &&
                res.data.data.type == 2
            ) {
              console.log("isLogin Flase type 2");
              //this.outLogin();
              localStorage.removeItem("userInfo");
              localStorage.removeItem("access_token");
              localStorage.removeItem("userId");
              localStorage.removeItem("typeInfo");
              localStorage.removeItem("access_tokenout");
              localStorage.removeItem("Zyaccess_token");
              localStorage.removeItem("idass");
              this.$store.state.access_token = "";
              this.$store.state.idass = "";
              this.$store.state.user = {};
              this.userId = "";
              this.userName = "";
            } else if (
                res.data.data.isLogin == false &&
                res.data.data.type == 1
            ) {
              console.log("isLogin Flase type 1");
              localStorage.removeItem("userInfo");
              localStorage.removeItem("access_token");
              localStorage.removeItem("userId");
              localStorage.removeItem("typeInfo");
              localStorage.removeItem("access_tokenout");
              localStorage.removeItem("Zyaccess_token");
              localStorage.removeItem("idass");
              this.userId = "";
              this.userName = "";
              this.$store.state.access_token = "";
              this.$store.state.idass = "";
              this.$store.state.user = {};
              this.login();
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getUser() {
      userDetail(this.userId).then((res) => {
        this.userInfo = res.data;
        localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
        localStorage.setItem("userId", res.data.gptsUserId);
        this.$store.state.user = res.data;
        this.$store.state.userId = res.data.gptsUserId;
        this.userId = res.data.gptsUserId;
        this.userName = this.userInfo.gptsUserName;
        this.avator=this.baseURL+this.userInfo.avatar
      });
    },
    outLogin() {
      // localStorage.removeItem("userId");
      // window.location.reload();
      logOut()
          .then((res) => {
            localStorage.removeItem("userInfo");
            localStorage.removeItem("access_token");
            localStorage.removeItem("userId");
            localStorage.removeItem("typeInfo");
            localStorage.removeItem("access_tokenout");
            localStorage.removeItem("Zyaccess_token");
            localStorage.removeItem("idass");
            this.$store.state.access_token = "";
            this.$store.state.idass = "";
            this.$store.state.user = {};
            // deleteUserAgent(localStorage.getItem("murmur"))
            let data = {
              userAgent: localStorage.getItem("murmur"),
            };
            this.$axios
                .post(
                    "https://www.oilgasinfoai.com/auth/oauth/deleteUserAgent",
                    data
                )
                .then(() => {
                  localStorage.removeItem("murmur");
                  window.location.href = res.data;
                });
          })
          .catch((error) => {
            console.error("退出登录失败:", error);
            this.$message.error("退出登录失败，请重试"); // 提示用户退出登录失败
            // 可以在此处执行其他的错误处理逻辑，例如重新加载页面或者显示其他提示信息
          });
    },
    login() {
      // this.$router.push("/login")
      // let href = "http://www.ogdri.com//";
      // let href = window.location.href.split("&code")[0];
      //
      // console.log(href)
      // getAuthorize(href).then((res) => {
      //   window.location.href = res.data.url;
      // });
    },
    routerSkip(link) {
      window.open(link, "_blank");
    },
    back() {
      window.open("http://cn.ogdri.com/", "_blank");
    },
    toEN(){
      const routeName = this.$route.name;
      if (routeName === "Home") {
        window.open("https://www.ogdri.com/", "_blank");
      }  else {
        window.open("https://www.ogdri.com/" + routeName, "_blank");
      }
    },
    toCN(){
      const routeName = this.$route.name;
      if(routeName==="Home"){
        window.open("https://cn.ogdri.com/", "_blank");
      }
      else{
        window.open("https://cn.ogdri.com/"+routeName, "_blank");
      }
    },
    English(){
      const routeName = this.$route.name;
      console.log(routeName)
      if(routeName==="Home"){
        window.open("https://www.ogdri.com/", "_blank");
      }
      else{
        window.open("https://www.ogdri.com/"+routeName, "_blank");
      }

    },
    gpts() {
      window.open("https://www.oilgascommunity.com/sortList?id=39", "_blank");
    },
    show() {

      this.$emit("showDialog");

    },
    hide() {
      this.dialogconsult = false;
    },
    // toggleScroll(){
    //   console.log("点击了",this.scrollState)
    //   let scrollPercentage = 0.03; // 这个变量就是一秒滚动这个滚动节点的视窗的百分之几
    //   // let scrollState = this.scrollState; // true为正在滚动
    //   // let scrollElement= document.getElementsByClassName("total")[0]; // 滚动节点
    //   let scrollElement= document.body; // 滚动节点
    //
    //   // ((() => { // 制造一个闭包避免变量污染环境
    //     // lastDistance = 0; // 滚动距离必须是整形，如果有小数则会被忽略，我就将小数存起来，然后取整
    //     let lastTime = 0;
    //
    //     const scroll = () => {
    //       if(!this.scrollState) { // 取消后停止
    //         return;
    //       }
    //
    //       let height = Math.min(window.innerHeight, scrollElement.getBoundingClientRect().height); // 视窗高度
    //       let time = (Date.now() - lastTime) / 1000; // 过了几秒
    //       this.lastDistance += height * scrollPercentage * time; // 计算总滚动高度
    //       // console.log(lastDistance, height * scrollPercentage * time, time)
    //       let distance = Math.floor(this.lastDistance);
    //       // lastDistance -= distance; // 取整，小数留给下一帧
    //       // console.log(distance)
    //       window.scrollTo({top: scrollElement.scrollTop + distance,behavior: 'smooth'}); // 立即滚动，避免css影响
    //       lastTime = Date.now();
    //       requestAnimationFrame(scroll); // 等待下一帧
    //     }
    //
    //     // return function() { // toggleScroll函数
    //       this.scrollState = !this.scrollState;
    //       // console.log(this.scrollState)
    //       if(this.scrollState) {
    //         lastTime = Date.now();
    //         requestAnimationFrame(scroll);
    //       }
    //     // };
    //   // })());
    // }
    toggleScroll() {
      // console.log("点击了", this.scrollState);
      let scrollPercentage = 0.003;
      let scrollElement = document.body; // 滚动节点

     this.lastTime=0

      const scroll = () => {
        if (!this.scrollState) { // 如果取消滚动，则停止
          return;
        }

        let height = Math.min(window.innerHeight, scrollElement.scrollHeight); // 视窗高度
        let distance = Math.floor(height * scrollPercentage); // 每帧滚动的距离
        let maxScroll = scrollElement.scrollHeight - window.innerHeight; // 最大滚动位置
        let newScrollTop = Math.min(Math.max(window.scrollY + distance, 0), maxScroll); // 限制滚动范围

        window.scrollTo({ top: newScrollTop, behavior: 'auto' }); // 立即滚动
        requestAnimationFrame(scroll); // 等待下一帧
      };

      this.scrollState = !this.scrollState; // 切换滚动状态
      if (this.scrollState) {
        this.lastTime = Date.now();
        requestAnimationFrame(scroll);
      }
    }
  },
};
</script>
<style>
.down-popover {
  min-height: 8vh !important;
  max-height: 62vh !important;
  overflow: auto;
  padding: 0 !important;
  margin-left: 1vw !important;
  background: linear-gradient(180deg,#f5f4f6,#e6ebf7) !important;
  box-shadow: 3px 0 8px 0px rgba(52, 48, 48, 0.8) !important;
  border: none !important;
}



.el-popper[x-placement^="bottom"] .popper__arrow::after {

  margin-left: -50px !important;

  border-bottom-width: 0;

  border-top-color: rgb(250, 250, 250) !important;

}

.el-popper[x-placement^=bottom] .popper__arrow {
  left: 10vw !important;
  border-bottom-color: rgb(250, 250, 250) !important;
}
</style>


<style lang="scss" scoped type="text/scss">
.avator {
  border: 2px solid #fff;
}
.searchBack {
  //background-color: rgba(102, 102, 102, 0.2);
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  column-gap: 1.5vw;
  height: 50vh;
  overflow-y: auto;
  row-gap: 2vh;
  padding: 0 2vw 2vh 2vw;
  list-style-type: none;
}.searchBack::-webkit-scrollbar-track-piece {
   background: linear-gradient(180deg,#f5f4f6,#e6ebf7);

 }

.searchBack::-webkit-scrollbar {
  width: 6px;
  transition: all 2s;
}

.searchBack::-webkit-scrollbar-thumb {
  background-color: #bdbbbb;
  border-radius: 100px;
}

.searchBack::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}

.searchBack::-webkit-scrollbar-corner {
  background: linear-gradient(180deg,#f5f4f6,#e6ebf7);
}
.searchBackno{
  display: flex;
  padding: 3vh 2vw;
  font-weight: bold;
  //justify-content: center;
  //align-items: center;
}
.searchContent {
  cursor: pointer;
  //background: #e5ebfa;
  border: white 1px solid;
  background-color: white;
  box-shadow: 5px 0 14px 0px #e5ebfa;
  padding: 2vh 1.5vw;
  height: 100%;
  border-radius: 9px;
  //color: white;

  .title1 {
    font-size: 1vw;
    font-weight: bold;
    line-height: 3.5vh;
  }

  .title2 {
    font-size: 0.8vw;
    line-height: 2.8vh;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
    overflow: hidden;
    text-overflow: ellipsis;
    height:5.6vh ;
  }
}

.searchContent:hover {
  border: #9b85f4 1px solid;


}
.topLayer{
  width: 100%;
  display: flex;
  padding: 3vh 2vw 2vw 2vw;
  background: linear-gradient(180deg,#f5f4f6, #edeff5);
  //position: fixed;
  ::v-deep .el-input {
    border-radius: 9px;
    height:3vh;
    font-size: 1vw;
  }
  ::v-deep .el-button--primary {
    height:5vh !important;
    font-size: 1vw;
  }
  ::v-deep .el-input__inner {
    border-radius: 9px;
    height:5vh !important;
    background-color: white !important;
  }
}
.search {
  border-radius: 9px;
  width: fit-content;
  background-color: transparent;
  margin-right: 0.5vw;
  cursor: pointer;

}
.userName {
  color: white;
  font-size: 1vw;
  line-height: 1.3;
  text-align: left;
  margin-left: 10px;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.layer1 {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 0 2vw;
  position: relative;

  #autoScroll{
    position: absolute;
    right: 1vw;
    top: 0;
    width: 3vw;
    height: 100%;
    cursor: pointer;
    // background-color: red;;
  }


  .leftLayer {
    position: absolute;
    left: 2%;
    cursor: pointer;
    width: 10.2790294627383vw;
    height: 5vh;
    background-image: url("../assets/home/bottomlOGO.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent; /* 这里设置背景颜色为透明 */
    //border: #2c3e50 1px solid;
  }

  .rightLayer {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center; /* Vertically center the content */
    .communicitynet {
      //z-index: 8;
      cursor: pointer;
      font-size: 1vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: bold;
     color: white;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      //border-radius: 53px;
      padding: 2.46242274412855vh 0;
      height: 4.56242274412855vh;
      overflow: hidden; /* Add overflow property */
      display: flex;
      position: relative;

      .net {
        background-image: url("../assets/earth2.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent; /* 这里设置背景颜色为透明 */
        width: 20px;
        height: 20px;
        right:10vw;

        position: absolute;
      }
    }

    .communicitynet:hover {
      //background: #bfd8f9;
      color: #ffffff;
    }
    .selectEn:hover {
      //color: #2f6eff;
      border-bottom: 3px solid white;
    }
    .selectCn:hover {
      border-bottom: 3px solid white;
    }
    ::v-deep .el-select .el-input__inner {
      border-radius: 9px;
      background-color: transparent;
      height: 3vh !important;
      width: 6vw;
      border: none !important;
      font-size: 1vw;
      //color: white;
      font-weight: bold;
      padding: 0 2vw;
      text-align: right;
    }
    ::v-deep .el-select .el-input .el-select__caret{
      color: white;
    }
    ::v-deep .el-input__suffix{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    ::v-deep .el-input__inner{
      color: white ;
    }
    }
    .communicityGPT {
      //z-index: 8;
      cursor: pointer;
      font-size: 1vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: bold;
      //color: #2f6eff;
      color: white;
      //background:#2sf6eff;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      //border-radius: 53px;
      padding: 2.46242274412855vh 0.5vw;
      height: 4.56242274412855vh;
      //border: #2f6eff 1px solid;
      //margin-right: 1.00065337954939vw;
      overflow: hidden;
    }

    .communicityGPT:hover {

      color: #FFFFFF;
      border-bottom: white 2px solid;
      //color: #1b1e76;
    }
    .communicity {
      //z-index: 8;
      cursor: pointer;
      font-size: 1vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: bold;
      color: white;
      //color: #2f6eff;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      //border-radius: 53px;
      padding: 2.46242274412855vh 0.5vw;
      height: 4.56242274412855vh;
      //border: #2f6eff 1px solid;
      //margin-right: 1.00065337954939vw;
      overflow: hidden; /* Add overflow property */
    }

    .communicity:hover {
      color: #ffffff;
      border-bottom: white 3px solid;
      //color: #1b1e76;
    }

    .loginUser {
      //z-index: 8;
      //border: white 1px solid;
      border-radius: 53px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      font-size: 1vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      color: white;
      line-height: 3.33745364647713vh;
      text-align: center;
      width: fit-content;
      height: 4.56242274412855vh;
      background: transparent;

      .flex {
        display: flex;
        align-items: center;
        height: 32px;
        margin-top: 12px;

        img.icon {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }

        .icon.hover {
          display: none;
        }
      }
    }

    .login {
      //z-index: 8;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      font-size: 1vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #2f6eff;
      line-height: 3.33745364647713vh;
      text-align: center;
      border-radius: 53px;
      width: fit-content;
      padding: 2.46242274412855vh 1.19896013864818vw;
      height: 4.56242274412855vh;
      background: white;
      border: white 1px solid;
    }

    .login:hover {
      color: white;
      background: rgba(255, 255, 255, 0.5);
      border: white 1px solid;
    }
  }


.layerModel {
  display: flex;
  justify-content: space-between;
  //background-color: white;
  .searchnew {
    background-color: #d7d7d7;
    position: absolute;
    left: 0;
    top:0;
    width: 100vw;
    height: fit-content;
    z-index: 99;

    .searchBack {
      margin-top: 108px;
      //background-color: rgba(102, 102, 102, 0.2);
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      padding: 0 15px;
      row-gap: 15px;
      list-style-type: none;
    }

    .searchBackno {
      padding: 15px 25px;
      font-weight: bold;
      text-align: center;
    }

    .searchContent {
      cursor: pointer;
      //background: #e5ebfa;
      border: white 1px solid;
      background-color: #f2f2f2;
      //box-shadow: 5px 0 14px 0px #e5ebfa;
      padding: 10px;
      height: 100%;
      border-radius: 9px;
      //color: white;

      .title1 {
        font-size: 14px;
        font-weight: bold;
        line-height: 22px;
      }

      .title2 {
        font-size: 12px;
        margin-top: 5px;
        line-height: 20px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
        overflow: hidden;
        text-overflow: ellipsis;
        height: 40px;
      }
    }

    .search {
      margin: 35px 5vw 25px 5vw;
      display: flex;
      border-radius: 9px;
      width: 90vw;
      background-color: white;

      ::v-deep .el-input {
        border-radius: 9px;
        width: 80vw;

        font-size: 14px;
      }

      ::v-deep .el-input__inner {
        border-radius: 9px;
        height: 30px !important;
        border: white 1px solid !important;
        background-color: transparent !important;
      }

    }
  }
  .userName {
    color: black;
    font-size: 14px;
    line-height: 1.3;
    text-align: left;
    margin-left: 10px;
    max-width: 50px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .leftLayer {
    cursor: pointer;
    width: 80px;
    height: 20px;
    background-image: url("../assets/GPTLOGO.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent; /* 这里设置背景颜色为透明 */
    //border: #2c3e50 1px solid;
  }

  .rightLayer {
    margin-top: -0.89443757725587vh;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center; /* Vertically center the content */
    .communicity {
      z-index: 8;
      cursor: pointer;
      font-size: 14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: white;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      border-radius: 53px;
      padding: 2.46242274412855vh 1.19896013864818vw;
      height: 4.56242274412855vh;
      border: white 1px solid;
      margin-right: 1.00065337954939vw;
      overflow: hidden; /* Add overflow property */
    }

    //.communicity:hover {
    //  background: rgba(255, 255, 255, 0.5);
    //}
    .communicitynet {
      z-index: 8;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      font-size: 14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: white;
      line-height: 15px;
      text-align: center;
      border-radius: 53px;
      width: fit-content;
      padding: 5px;
      height: 35px;

      ::v-deep .el-input {
        border-radius: 9px;
        width: 75px;
        font-size: 14px;
        color: black;
      }
      ::v-deep .el-select .el-input .el-select__caret{
        color: black;
      }

      ::v-deep .el-input__inner {
        border-radius: 9px;
        color: black;
        height: 5vh !important;
        border: none !important;
        background-color: transparent !important;
      }

      .net {
        background-image: url("../assets/netModel.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent; /* 这里设置背景颜色为透明 */
        width: 14px;
        height: 14px;
      }
    }

    //.communicitynet:hover {
    //  color: #2f6eff;
    //  background: rgba(255, 255, 255, 0.5);
    //  border: #2f6eff 1px solid;
    //}

    .loginUser {
      z-index: 8;
      //border: #2F6EFF 1px solid;
      border-radius: 53px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      font-size: 14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      color: white;
      line-height: 3.33745364647713vh;
      text-align: center;
      width: fit-content;
      height: 4.56242274412855vh;
      background: transparent;

      .flex {
        display: flex;
        align-items: center;
        height: 32px;
        margin-top: 12px;

        img.icon {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }

        //.icon.hover {
        //  display: none;
        //}
      }
    }

    .login {
      z-index: 8;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center; /* Vertically center the content */
      font-size: 14px;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      color: #ffffff;
      line-height: 15px;
      text-align: center;
      border-radius: 53px;
      width: fit-content;
      padding: 10px;
      height: 35px;
      font-weight: bold;
      //background: white;
      //border: #2f6eff 1px solid;
    }

    //.login:hover {
    //  color: #2f6eff;
    //  background: rgba(255, 255, 255, 0.5);
    //  border: #2f6eff 1px solid;
    //}
  }
}
.layer2 {
  .title1 {
    margin:0 auto;
    width: fit-content;
    height:23px;
    font-size: 23px;
    font-family: Sarial, sans-serif, "Microsoft Yahei";
    font-weight: 500;
    color: #111111;
    line-height: 8.23733003708282vh;
  }
  .title2 {
    margin:35px auto 0 auto;
    width: fit-content;
    height:17px;
    font-size: 14px;
    font-family: Sarial, sans-serif, "Microsoft Yahei";
    font-weight: 400;
    color: #111111;
    line-height:24px;
  }
  .title3 {
    display: flex;
    text-align: center;
    margin: 20px auto 0 auto;
    width: fit-content;
    height: 35px;
    font-size: 8px;
    font-family: Sarial, sans-serif, "Microsoft Yahei";
    font-weight: bold;
    color: white;
    line-height:22px;
  }
}
.layer3 {
  position: absolute;
  display: flex;
  bottom:15px;
  opacity: 1;
  width: 90%;
  justify-content: center;
  .communicity {
    //z-index: 8;
    cursor: pointer;
    font-size: 16px;
    font-family: Sarial, sans-serif, "Microsoft Yahei";
    font-weight: 400;
    color: #2f6eff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center; /* Vertically center the content */
    border-radius: 53px;
    width: fit-content;
    margin:0 15px;
    padding: 10px 4.6875vw;
    height: 35px;
    border: #2f6eff 1px solid;
    overflow: hidden; /* Add overflow property */
  }
  .communicityGpt {
    //z-index: 8;
    cursor: pointer;
    font-size: 16px;
    font-family: Sarial, sans-serif, "Microsoft Yahei";
    font-weight: 400;
    color:white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center; /* Vertically center the content */
    border-radius: 53px;
    width: fit-content;
    margin:0 15px;
    padding: 10px 4.6875vw;
    height: 35px;
    background-color:#2f6eff ;
    border: #2f6eff 1px solid;
    overflow: hidden; /* Add overflow property */
  }

}
</style>
