<script >

export default {
  data(){
    return{
      showTooltip:false,
    }
  },
  computed:{
    // isPC() {
    //   let creenWidth=window.innerWidth
    //   console.log("this.creenWidth-------",creenWidth)
    //   return creenWidth>1000;
    // },
  },
  mounted() {

    // let creenWidth=window.innerWidth
    // console.log("-------------creenwidth",creenWidth)
    // // if(creenWidth<1000){
    // //
    // // }
  },


  methods:{
    toggleTooltip() {
      this.showTooltip = !this.showTooltip; // 切换二维码显示与隐藏
    },
    uptop(){
      console.log("zhiding")
      this.$emit("uptop")
    },
    upkefu(){
      window.open("https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=3588905289569767&webPageTitle=在线咨询")
    },
    showNet(){
      window.open("/netFeedback")
    },
    showProduct(){
      console.log("this.isPC",this.isPC)
      if(this.isPC){
        this.$emit("showProduct", true)
      }else{
        this.$emit("showProduct", false)
      }

    },
  }
}
</script>

<template>

  <div class="mobile-style">
    <div class="mobile1">

      <div style="padding: 1vh 0;">
        <!-- <div class="upkefu3"></div> -->
        <!-- <div class="title1" style="font-weight: 400;"  > 关注我们</div> -->
        <!-- <div class="wechaticon" @mouseenter="showTooltip=true"
             @mouseleave="showTooltip=false"> -->
        <!--         <div class="wechaticon" @click="toggleTooltip" >-->
        <div class="wechaticon" >
          <img src="../assets/home/wxattention.jpg" class="wechat-icon"/>
          <div class="title1" style="color: black" > 扫码关注<br>智启云端研究院</div>
        </div>
        <!--    <div class="tooltip" v-if="showTooltip">-->
        <!--      <img src="../assets/home/wxattention.jpg"/>-->
        <!--    </div>-->
      </div>
    </div>
    <div >
<!--      <div class="uptop"  @click="uptop"></div>-->
      <div class="mobile2" style="padding: 5px ;" @click="upkefu">
        <div class="upkefu" ></div>
        <div class="title1" style="font-weight: 400;font-size: 10px;line-height: 12px"> 在线<br>咨询</div>
      </div>
      <div class="mobile3" style="padding: 5px;" @click="showProduct">
        <div class="upkefu1"></div>
        <div class="title1" style="font-weight: 400;font-size: 10px;line-height: 12px" > 服务<br>反馈</div>
      </div>
    </div>

</div>
</template>

<style scoped lang="scss">
.pc-style {
  z-index: 99;
  cursor: pointer;
  position: fixed;
  top: 40%;
  right: 1%;
  padding: 5px;
  width: 3vw;
  height: 14vw;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  // background-color: rgb(50, 119, 220);
  // background-color: #15257b;
  background-color: rgba(8,78,138,1);

  //border-radius: 50%;
  //box-shadow: 5px 0 14px 0px #D4E4FF;

  .uptop {
    width: 2vw;
    height: 2vw;

    margin: 1vh auto;
    background-image: url("../assets/up.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }

  .upkefu {
    margin: 0 auto;
    width: 2vw;
    height: 2vw;
    background-image: url("../assets/kefu2.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .upkefu1 {
    margin: 0 auto;
    width: 2vw;
    height: 2vw;
    background-image: url("../assets/Frame (1).png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .sidebar {
  padding: 0 0 1vh;
  position: relative;
}
  .upkefu3 {
    margin: 0.5vh auto;
    width: 2vw;
    height: 2vw;
    background-image: url("../assets/关注1.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .title1{

    font-size: 0.5vw;text-align: center;;color: white;  cursor: pointer; /* 可点击手势 */
  }
  .wechaticon {
  height:3vh;
  width: 2.5vw;
  // display: flex;
  justify-content: center;
  text-align: center;
  margin:0 auto;
  z-index: 99999
    // background-color: #f0f0f0;
    // transition: background-color 0.3s; /* 平滑过渡效果 */
  }
  .wechat-icon {
    object-fit: contain;
    width: 25px;   /* 设置图片宽度 */
    height: 25px;  /* 设置图片高度 */
}
 .tooltip {
    /* 当showTooltip为true时显示提示框 */
    opacity: 1;
    width: 15vh;
    height: 15vh;
    position: fixed;
    right: 4.5vw;
    top: 55vh;
  }

  // .tooltip {
  //   opacity: 0;
  //   visibility: hidden;
  //   transition: opacity 0.3s ease, visibility 0s 0.3s;
  // }

  // .wechaticon:hover + .tooltip {
  //   opacity: 1;
  //   visibility: visible;
  //   transition: opacity 0.3s ease, visibility 0s 0s;
  // }
  .qr-codes {
  position: absolute;
  top:3vh; /* 从文字底部开始 */
  left: 50%;
  transform: translateX(-50%);
  display: none; /* 默认隐藏 */
  flex-direction: column;
  align-items: center;
  gap: 1vh;
}

.qr-code {
  width: 3vw;
  height: 3vw;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  opacity: 0; /* 隐藏二维码 */
  transform: translateY(10px);
  transition: opacity 0.3s, transform 0.3s; /* 平滑过渡效果 */
}

.sidebar:hover .qr-codes {
  display: flex;
}

.sidebar:hover .qr-code {
  opacity: 1;
  transform: translateY(0); /* 上下动画效果 */
}
  .feedback {
    padding: 0.5vh 0;
    margin: 1vh auto;
    font-size: 0.8vw;
    text-align: center;
    color: white;
  }

}
.mobile-style{
  //cursor: pointer;
  //position: fixed;
  //display: flex;
  //justify-content: space-between;
  //flex-direction: column;
  //top:40%;
  //height: 100vh;

  //right:0;
  //background-color: rgb(8, 78, 137);
  //padding:10vh 0 20vh 0;
  //width: fit-content;

  //border-radius: 50%;
  //border: 5px solid #1295d9;
  .mobile1{
    //z-index: 9999  ;
    position: fixed;
    top:20vh;
    right:0;
    width: 20vw;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    background-color: white;
  }
  .mobile2{
    position: fixed;
    bottom:30vh;
    right:0;
    background-color: #121d71;
    display: flex;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    display: flex;
    align-content: center;
  }
  .mobile3{
    position: fixed;
    bottom:20vh;
    right:0;
    background-color: #121d71;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    display: flex;
    align-content: center;
  }
  .uptop{
    width:25px;
    height:25px;
    margin:5px auto;
    background-image: url("../assets/up.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .upkefu{
    margin:0 auto;
    width: 20px;
    height: 20px;
    background-image: url("../assets/Vector (3).png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .upkefu1{
    margin:0 auto;
    width: 25px;
    height: 25px;
    background-image: url("../assets/Frame.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .upkefu3 {
    margin: 0.5vh auto;
    width: 15px;
    height: 15px;
    background-image: url("../assets/关注1.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
  }
  .title1{

    font-size: 7px;text-align: center;margin:auto 0 auto 0;color: white;
    display: flex;

    height: fit-content;
    align-content: center;
  }
  .wechaticon {
    // height:9vh;
  width:14vw;
  // display: flex;
  justify-content: center;
  text-align: center;
  margin:0 auto;
  z-index: 99999
  }
  .wechat-icon {
    object-fit: contain;
    width: 35px;   /* 设置图片宽度 */
    height: 35px;  /* 设置图片高度 */
}
 .tooltip {
    /* 当showTooltip为true时显示提示框 */
    // opacity: 1;
    opacity: 1;
    width: 8vh;
    height: 8vh;
    position: fixed;
    right: 17vw;
    top: 58vh;
  }

  // .wechaticon:hover {
  //   background-color: #3498db; /* 鼠标悬停时的背景颜色 */
  // }

  .feedback{
    cursor: pointer;
    width: fit-content;
    height:fit-content;
    color: white;
    font-size: 8px;
    margin:0 auto;
    text-align: center;
    //padding: 10px 0;
    display: flex;
    justify-content: center;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    //border: 5px solid #1295d9;
  }
}
</style>
