<script >
import Swiper from "swiper";

export default {
  data(){
    return{

    }
  },
  mounted() {
    this.initSwiper();
  },
  methods:{
    upkefu(){
      window.open("https://channel-scrm.xiaoshouyi.com/embeded/im/iframe-h5.html?tenantToken=60cb1c79deb2cf2b3e2c53ad2ed91d09&siteId=3588905289569767&webPageTitle=在线咨询")
    },
    initSwiper() {
      this.swiper = new Swiper(".swiper-container02", {
        autoplay: 3000,
        disableOnInteraction: true,
        slidesPerView: 1,
        spaceBetween: 10,
        loop: false,
        pagination: ".swiper-pagination",
        paginationType: "bullets",
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        paginationClickable: true,
        pauseOnMouseEnter: true, // 鼠标悬停时暂停自动播放

      });
    }

  }

}
</script>

<template>
<div class="researchContainer">
  <div class="title">
    我们的研究
  </div>
<div class="titlenew">
  深度融合工程地质，直击油气田开发难题，增产增效精准出击
</div>
  <div class="swiper-container02" style="position: relative">
    <div class="swiper-wrapper">
      <div class="swiper-slide" @click="recog">
        <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
        <div class="grand1" style="background-color: white">
          <div class="imgDesign" >
            <img src="../../assets/1735031294646_技术 1.png">
          </div>
          <div class="titleDesign">
            <div class="title1">
              非常规油气藏精细地质研究
            </div>
            <!-- <div class="title2">
              深入长庆陇东页岩油储层，开展了多维度、全⽅位加深油⽓藏认识，形成以“点、线、⾯、体”相互联动、动态结合的油⽓藏开发整体解决⽅案，实现油⽓藏⾼效开发。
            </div> -->
            <div class="learnMore" @click="upkefu()">
              <!-- 了解更多 > -->
               定制您的专属研究课题
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-slide" @click="recog">
        <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
        <div class="grand1" style="background-color: white">
          <div class="imgDesign" >
            <img src="../../assets/1735031294646_技术 2.png">
          </div>
          <div class="titleDesign">
            <div class="title1">
              老井复产增产技术
            </div>
            <!-- <div class="title2">
              在塔里木油田，以提高产量作为核心目标，针对处于生产中后期的产油井、产气井和注水井实施了一系列措施，在老井提产增效方面成效显著。
            </div> -->
            <div class="learnMore" @click="upkefu()">
              <!-- 了解更多 > -->
              定制您的专属研究课题
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-slide" @click="recog">
        <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
        <div class="grand1" style="background-color: white">
          <div class="imgDesign" >
            <img src="../../assets/1735031294646_技术 3.png">
          </div>
          <div class="titleDesign">
            <div class="title1">
              深层⻚岩⽓⾼效改造
            </div>
            <!-- <div class="title2">
              深探泸州**井攻克深层页岩气构造复杂、施工难度大等难题，科学管控工程风险，创新工艺优化流程，显著提升了改造效果，为油气田高效开发筑牢根基。
            </div> -->
            <div class="learnMore" @click="upkefu()">
              <!-- 了解更多 > -->
              定制您的专属研究课题
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-slide">
        <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
        <div class="grand1" style="background-color: white">
          <div class="imgDesign" >
            <img src="../../assets/home/research1.png">
          </div>
          <div class="titleDesign">
            <div class="title1">
              边际油田的高效开发
            </div>
            <!-- <div class="title2">
              在塔里木油田，以提高产量作为核心目标，针对处于生产中后期的产油井、产气井和注水井实施了一系列措施，在老井提产增效方面成效显著。
            </div> -->
            <div class="learnMore" @click="upkefu()">
              <!-- 了解更多 > -->
              定制您的专属研究课题
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-slide">
        <!--        <img src="@a/Q.jpeg" alt="图片2" />-->
        <div class="grand1" style="background-color: white">
          <div class="imgDesign" >
            <img src="../../assets/home/research2.png">
          </div>
          <div class="titleDesign">
            <div class="title1">
              开发中后期老油田增产稳产
            </div>
            <!-- <div class="title2">
              深探泸州**井攻克深层页岩气构造复杂、施工难度大等难题，科学管控工程风险，创新工艺优化流程，显著提升了改造效果，为油气田高效开发筑牢根基。
            </div> -->
            <div class="learnMore" @click="upkefu()">
              <!-- 了解更多 > -->
              定制您的专属研究课题
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
    <div class="swiper-pagination"></div>
  </div>
</div>
</template>

<style scoped lang="scss">
.researchContainer{
  .title{
    color: white;
    padding:15px 0 15px 10px;
    font-weight: bold;
    font-size: 18px;
  }
.titlenew{
  color: white;
  font-size: 16px;
    padding: 0 10px 2vh 10px;
}
  .imgDesign{
    width: 100vw;
    height: 50vw;
    color: black;
  }
  .titleDesign{
    padding: 0 10px;
    .title1{
      font-size: 14px;
      color: black;
      letter-spacing: 0.3vw;
      margin: 15px 0;
    }
    .title2{
      font-size: 4vw;
      color: #898989;
      height: 214px;
    }
    .learnMore{
      // font-size: 14px;
      // font-weight: bolder;
      color: #0B71D6;
      padding: 0 0 15px 0;
    }
  }
  ::v-deep .swiper-button-prev{
    background-image:url("../../assets/Group 32.png");
    background-size: contain;
    width: 7vw;
    top: 214px;
    height: 7vw;
  }
  ::v-deep .swiper-button-next{
    background-image:url("../../assets/Group 31.png");
    background-size: contain;
    width: 7vw;
    top: 214px;
    height: 7vw;
  }

  ::v-deep .swiper-pagination-bullets{
    padding: 1vh 0 4vh 0;

  }
  ::v-deep .swiper-pagination-bullet{
    background-color: white;

  }
}
</style>
